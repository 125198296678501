const SET_BASKET = 'BASKET/SET_BASKET';
const SET_LOADING = 'BASKET/SET_LOADING';
export let basketInitialState = {
    loading: 'none',
    basket: null,
};
export function setInitialBasketState(data) {
    basketInitialState = {
        ...basketInitialState,
        ...data,
    };
}
export default function basketReducer(state = basketInitialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
        case 'BASKET/SET_LOADING': {
            const newState = { ...state };
            newState.loading = action.payload;
            return newState;
        }
        case 'BASKET/SET_BASKET': {
            const newState = { ...state };
            newState.basket = action.payload;
            return newState;
        }
    }
}
