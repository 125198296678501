const SET_NAV = 'CUSTOMER_PORTAL/SET_NAV';
export const customerPortalInitialState = {
    navOpen: true,
};
export default function customerPortalReducer(state = customerPortalInitialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
        case 'CUSTOMER_PORTAL/SET_NAV': {
            const newState = { ...state };
            newState.navOpen = action.payload;
            return newState;
        }
    }
}
