import { isSSR } from '@helpers/ssr';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './ducks';
import { appInitialState } from './ducks/app/app.reducer';
import { basketInitialState } from './ducks/basket/basket.reducer';
import { customerPortalInitialState, } from './ducks/customerportal/customerportal.reducer';
import { pageContextInitialState } from './ducks/pagecontext/pagecontext.reducer';
import { userInitialState } from './ducks/user/user.reducer';
const composeEnhancers = composeWithDevTools || compose;
export default function getStore(reduxContext) {
    if (reduxContext === undefined) {
        throw new Error(`If you're seeing this error you probably are trying to render a react component that requires redux and hasn't been set as WithRedux="true" on the ReactComponent user control. Or the root component you are trying to render isn't wrapped with 'withRedux(Component)'. \r\n \r\n React Context Prop: \r\n ${JSON.stringify(reduxContext)}`);
    }
    if (isSSR() || window.REDUX_STORE === undefined) {
        // Create the initial state from the redux context and initial states from the reducers
        const initialState = {
            app: {
                ...appInitialState,
                ...reduxContext.app,
            },
            basket: {
                ...basketInitialState,
                ...reduxContext.basket,
            },
            pageContext: {
                ...pageContextInitialState,
                ...reduxContext.pageContext,
            },
            customerPortal: {
                ...customerPortalInitialState,
                ...reduxContext.customerPortal,
            },
            user: {
                ...userInitialState,
                ...reduxContext.user,
            },
        };
        const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware()));
        if (isSSR()) {
            return store;
        }
        else {
            window.REDUX_STORE = store;
        }
    }
    // Return the store
    return window.REDUX_STORE;
}
export const exampleState = {
    app: {
        customerId: '',
        isLoggedIn: true,
        loginModalType: undefined,
        myProfileUrl: '',
        loginUrl: '',
        registerUrl: '',
        myHiresUrl: '',
        emailAddress: null,
        checkoutConfirmationUrl: '',
        checkoutPaymentUrl: '',
        checkoutOverviewUrl: '',
        checkoutDetailsUrl: '',
        basketItemsCount: 0,
        welcomeMessage: null,
        excludedDates: [{ startDate: '12-25-2021', endDate: '01-01-2022', isRecurring: false }],
    },
    basket: {
        loading: 'none',
        basket: {
            order: {
                g42OrderNumber: '',
                accountName: '',
                accountEmail: '',
                headOfficeAddress: {
                    address1: '',
                    address2: '',
                    city: '',
                    postcode: '',
                },
                deliveryFirstName: 'John',
                deliveryLastName: 'Doe',
                contactNumber: '01234 567890',
                contactEmail: 'john.doe@example.com',
                siteName: 'Acme Construction Site',
                siteAddress: {
                    address1: '123 Acme Road',
                    address2: 'Acme town',
                    city: 'Acme city',
                    postcode: 'AC3 MEE',
                },
                additionalNotes: 'I love hiring stuff',
                poNumber: '',
                orderLines: [
                    {
                        productId: 1,
                        productGroupName: 'Gooseneck Extensions',
                        productName: 'EMV Gooseneck x 2.0m',
                        productImageUrl: 'Groundforce/Images/Piletec/gooseneck_thumb_eljlex',
                        sku: 'GN.30T',
                        quantity: 1,
                        hireDateStart: '2021-10-05T00:00:00',
                        hireDateEnd: '2021-10-13T00:00:00',
                        ratePrice: 550,
                        ratePriceFormatted: '£550.00',
                        rateDescription: 'Weekly',
                        leadTimeDays: '0',
                        lineNotes: '',
                        lineTotal: 1,
                        lineTotalFormatted: '£1.00',
                        lineQuestions: [{ questionText: 'Do you need XYZ accessory?', answerText: 'No' }],
                    },
                ],
            },
        },
    },
    pageContext: {
        documentCulture: 'en-GB',
        documentGuid: '12345',
        nodeAliasPath: '',
        nodeId: 1,
    },
    customerPortal: {
        navOpen: true,
    },
    user: {
        details: {
            initials: 'BA',
            firstName: 'Ben',
            lastName: 'Adlington',
            emailAddress: 'benadlington@netconstruct.co.uk',
            passwordLength: 8,
            companyName: 'NetConstruct',
            companyAddress: {
                address1: 'Central House',
                address2: 'Otley Rd',
                city: 'Harrogate',
                postcode: 'HG3 1UG',
            },
        },
    },
};
export const exampleReduxContext = {
    app: exampleState.app,
    basket: exampleState.basket,
    pageContext: exampleState.pageContext,
    customerPortal: exampleState.customerPortal,
    user: exampleState.user,
};
